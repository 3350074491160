

<template>
  <div class="container">

    <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>


    <div class="nf-tabs">
      <div class="nf-tabs-nav">
        <router-link
          v-for="(nav, index) in tabsNav"
          :key="index"
          class="nf-tabs-nav-label"
          :class="{'is-active': activeNav === index}"
          :to="`${nav.url}`"
        >
          <i v-if="nav.name === '待办申请'" class="icon icon-approval"></i>
          <i v-if="nav.name === '全部申请'" class="icon icon-application"></i>
          <span>{{nav.name}}</span>
        </router-link>
      </div>
      <div class="nf-tabs-content">
        

    <div class="apply-manage">
      <el-form :inline="true" :model="filterForm" class="filter-form">
        <el-form-item label="开证种类">
          <el-select clearable class="form-row" v-model="filterForm.credentialType" size="small" placeholder="请选择开证种类"
                     @change="selectCredentialType">
            <el-option label="拆违相关" value="1"></el-option>
            <el-option label="优惠相关" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单位名称">
          <el-input class="form-row" v-model="filterForm.search" size="small" placeholder="请输入单位名称"></el-input>
        </el-form-item>
        <el-form-item label="申请时间">
          <div class="block">
            <el-date-picker
                    style="width:17rem"
                    v-model="filterForm.applyTime"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    size="small"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间">
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>

      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column align="center" prop="credentialType" label="开证种类">
          <template slot-scope="scope">
            <div>{{ scope.row.credentialType === 1 ? '拆违相关':'优惠相关'}}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="companyName" label="开证单位"></el-table-column>
        <el-table-column align="center" prop="createdDate" label="申请时间">
          <template slot-scope="scope">
            <div>{{ scope.row.createdDate }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="applyStatus" label="审核状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status === 1" style="color: #FD8F36">待审核</span>
            <span v-if="scope.row.status === 2" style="color: #41B873">已通过</span>
            <span v-if="scope.row.status === 3" style="color: #F23D2F">已驳回</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="150">
          <!--          <template slot-scope="scope">-->
          <!--            <el-button @click="detailsRow(scope.row, 1)" type="text" size="small">详情</el-button>-->
          <!--            <el-button @click="detailsRow(scope.row, 2)" type="text" size="small">审核</el-button>-->
          <!--          </template>-->
          <template slot-scope="scope">
            <el-button @click="detailsRow(scope.row, 1)" type="text" size="small">详情</el-button>
            <el-button
                    @click="detailsRow(scope.row, 2)"
                    v-if="scope.row.approver" type="text" size="small">审核
            </el-button>
            <el-button v-else :disabled="true" type="text" size="small">审核</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="table-pagination">
        <el-pagination
                background
                layout="total, prev, pager, next, jumper"
                :total="total"
                :page-size="pageSize"
                :current-page.sync="pageNum"
                @current-change="paginationChange"
        ></el-pagination>
      </div>
    </div>

  </div>
    </div>

    <!-- 审批 - 弹出框 -->
    <el-dialog :title="detailTitle" :visible.sync="detailVisible" width="600px" top="3%" align="center">
      <applyDetail v-if="detailVisible" :detailapply="detailapply" :detailData="detailData" @applySave="applySave"
                   @applyClose="applyClose"/>
    </el-dialog>
  </div>
</template>

<script>
  /* COMPONENT DOCUMENT
  * author: David.dai
  * date: 2020/07/16
  * desc: 开证申请-待办申请
  */
  import applyDetail from './components/applyDetail';
import nfBreadcrumb from "@/component/breadcrumb/breadcrumb";

  export default {
    name: 'upcomingApply',

    components: {
      applyDetail,
      nfBreadcrumb
    },

    data() {
      return {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        status: 1,
        // 筛选表单 - 值
        filterForm: {
          credentialType: '', // 开证类型
          search: '', // 单位名称
          applyTime: [], // 绑定事件数组
          startDate: '', //开始 时间
          endDate: '' // 结束 时间
        },
        // 列表数据
        tableData: [],
        detailVisible: false, // 详情弹出框状态
        detailTitle: '申请详情', // 弹出框标题
        detailData: {}, // 弹出框数据
        detailapply: 1, // 1、详情、2、审批
        showApply: localStorage.getItem('idLeaderNb'),

        breadList: [
        {
          name: "业务协同"
        },
        {
          name: "开证申请"
        }
      ],
      activeNav: 0,
        tabsNav: [
          {
            name: "待办申请",
            url: "upcomingApply"
          },
          {
            name: "全部申请",
            url: "allApply"
          }
        ],
      };
    },

    created() {
      this.getListData();
    },

    methods: {
      // 查询
      onSubmit() {
        this.getListData();
        this.pageNum = 1;
      },

      // 获取列表数据
      async getListData(page) {
        const params = {
          pageNum: page || 1,
          pageSize: this.pageSize,
          // eslint-disable-next-line radix
          credentialType: parseInt(this.filterForm.credentialType),
          status: this.status,
          search: this.filterForm.search,
          startDate: this.filterForm.applyTime ? this.filterForm.applyTime[0] : '',
          endDate: this.filterForm.applyTime ? this.filterForm.applyTime[1] : ''
        }; // 代办
        if (Array.isArray(this.filterForm.applyTime) && this.filterForm.applyTime.length >= 2) {
          params.startDate = params.startDate + ' 00:00:00';
          params.endDate = params.endDate + ' 23:59:59';
        }
        // 代办
        const data = await this.$fetchData.fetchPost(params, this.$fetchApi.issuingApplyListApply.api, 'json');
        if (data.code === '200') {
          if (data.result && data.result.rows) {
            this.tableData = data.result.rows;
            this.total = data.result.total;
          }
        } else {
          this.$message({ type: 'error', message: data.message });
        }
      },

      // 详情 / 审批
      async detailsRow(data, type) {
        // console.log(data, '111');
        this.detailapply = type;
        this.detailTitle = type === 1 ? '申请详情' : '审核开证申请';
        const res = await this.$fetchData.fetchGet({}, this.$fetchApi.issuingApplyDetail.api + data.id);
        if (res.code === '200') {
          this.detailVisible = true;
          this.detailData = res.result;
        } else {
          this.$message({ type: 'error', message: res.message });
        }
      },

      // 提交审批
      async applySave(data) {
        const params = {
          id: data.id,
          approvalStatus: data.type,
          refuseReason: data.reason
        };

        const api = this.$fetchApi.issuingApplyisOK.api;
        const res = await this.$fetchData.fetchPost(params, api, 'json');
        if (res.code === '200') {
          this.$message({ type: 'success', message: '已成功提交审批' });
          this.getListData(this.pageNum);
          this.detailVisible = false;
        } else {
          this.$message({ type: 'error', message: '非局办公室人员无法审核' });
        }
      },

      // 关闭弹窗
      applyClose() {
        this.detailVisible = false;
      },

      // 改变页码
      paginationChange(page) {
        this.getListData(page);
        this.pageNum = page;
      },
      // 选择开证类型
      selectCredentialType(e) {
        console.log(e, 'eee');
        this.filterForm.credentialType = e;
      }
    }
  };
</script>
<style lang='less' scope>
  .apply-manage {
    padding: 20px 10px;

    .el-form-item {
      margin-right: 25px;

      &:last-child {
        margin-right: 0;
      }
    }

    .table-pagination {
      text-align: center;
      margin: 40px 0;
    }
  }

  .nf-tabs {
  max-width: 1200px;
  min-height: 600px;
  display: flex;
  margin: 0 auto;

  .icon {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    background: none no-repeat center;
    background-size: 100%;
    margin-right: 4px;

    &-approval {
      background-image: url("../../assets/images/dutyManagement/icon_approval.png");
    }

    &-application {
      background-image: url("../../assets/images/dutyManagement/icon_application.png");
    }
  }

  &-nav {
    min-width: 170px;
    max-width: 170px;
    margin-right: 10px;
    background: #ffffff;
    box-shadow: 2px 0px 3px 0px rgba(38, 90, 225, 0.15);

    &-label {
      display: block;
      height: 60px;
      line-height: 60px;
      padding: 0 48px 0 24px;
      font-weight: 700;
      color: #3380fe;

      &.is-active {
        background: url("../../assets/images/officialDocument/pic_tab_nav_bg.png")
          center no-repeat;
        background-size: 100%;
      }
    }
  }

  &-content {
    width: 100%;
  }
}
</style>
